import { Col, Row } from "antd";
import {
  FileTextOutlined,
  GithubOutlined,
  LinkedinOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import FabButtons from "../components/FabButtons";
import ProfileImage from "../assets/img/profile.jpeg";
import { gerarPdf } from "../util/util";
import mailIcon from "../assets/icons/mail.png";
import mapIcon from "../assets/icons/map.png";
import whatsAppIcon from "../assets/icons/whatsapp.png";

export default function Curriculo() {
  return (
    <div>
      <FabButtons
        actions={[
          {
            icon: <FileTextOutlined />,
            text: "Baixar Currículo",
            handler: (e: any) => {
              e.preventDefault();
              gerarPdf();
            },
            color: "white",
            href: "#",
          },
          {
            icon: <WhatsAppOutlined />,
            text: "WhatsApp",
            handler: null,
            color: "#4ecb5c",
            linkColor: "white",
            href: "https://api.whatsapp.com/send?phone=5541997584227&text=Ol%C3%A1,%20meu%20nome%20%C3%A9%20",
          },
          {
            icon: <MailOutlined />,
            text: "E-mail",
            handler: null,
            color: "#7a4ecb",
            linkColor: "white",
            href: "mailto:me@felipeborba.dev",
          },
          {
            icon: <GithubOutlined />,
            text: "GitHub",
            handler: null,
            color: "black",
            linkColor: "white",
            href: "https://github.com/mrfsbDev",
          },
          {
            icon: <LinkedinOutlined />,
            text: "LinkedIn",
            handler: null,
            color: "#2966c2",
            linkColor: "white",
            href: "https://www.linkedin.com/in/felipe-borba-663b6b149",
          },
        ]}
      />

      <div className="container" id="container">
        <Row wrap={true}>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="col-1">
            <div className="container-profile">
              <img src={ProfileImage} className="profile-image" alt="profile" />
            </div>
            <div className="descriptions-col-2">
              <hr className="divider" />
              <h2>RESUMO PROFISSIONAL</h2>
              <p>
                Seis anos de experiência em desenvolvimento de software,
                utilizando as tecnologias JavaScript/TypeScript, NodeJS e C#,
                com foco em entregar valor aos projetos em que participo,
                utilizando as boas práticas de desenvolvimento de software e
                metodologias ágeis.
              </p>
              <hr className="divider" />
              <h2>SOFT SKILLS</h2>
              <div className="descriptions-skills">
                <ul>
                  <li>Proatividade.</li>
                  <li>Habilidades em comunicação.</li>
                  <li>Trabalho em equipe.</li>
                  <li>Resolução de problemas.</li>
                  <li>Dedicação com o trabalho.</li>
                  <li>Perseverança.</li>
                  <li>Autodidata.</li>
                </ul>
              </div>
              <h2>HARD SKILLS</h2>
              <div className="descriptions-skills">
                <ul>
                  <li>
                    <strong>BACK-END</strong>
                    <ul className="descriptions-skills">
                      <li>
                        <strong>Linguagens de programação: </strong>
                        JavaScript/Typescript (NodeJS), C#.
                      </li>
                      <li>
                        <strong>Linguagens de consulta: </strong>SQL, MQL,
                        GraphQL.
                      </li>
                      <li>
                        <strong>Frameworks:</strong> NestJS, ASP.NET, ASP.NET
                        Core.
                      </li>
                      <li>
                        <strong>Conteinerização e orquestração: </strong>
                        Docker, Kubernetes
                      </li>
                      <li>
                        <strong>Mensageria: </strong>Apache Kafka
                      </li>
                      <li>
                        <strong>Testes: </strong>Jest, SuperTest, Mocha.
                      </li>
                    </ul>
                  </li>
                </ul>

                <ul>
                  <li>
                    <strong>FRONT-END</strong>
                    <ul className="descriptions-skills">
                      <li>HTML, CSS, JavaScript.</li>
                      <li>React.</li>
                      <li>React Native.</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>DATABASES</strong>
                    <ul className="descriptions-skills">
                      <li>PostgreSQL.</li>
                      <li>SQL Server.</li>
                      <li>MySQL.</li>
                      <li>MongoDB.</li>
                      <li>Redis.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} className="col-2">
            <h1>FELIPE DOS SANTOS BORBA</h1>
            <div className="contacts">
              <ul>
                <li>
                  <img src={mapIcon} alt="email" className="icon" />
                  <a className="contact-label" href="#">
                    Curitiba - PR
                  </a>
                </li>
                <li>
                  <img src={mailIcon} alt="email" className="icon" />
                  <a
                    className="contact-label"
                    href="mailto:me@felipeborba.dev"
                    rel="noreferrer"
                  >
                    me@felipeborba.dev
                  </a>
                </li>
                <li>
                  <img src={whatsAppIcon} alt="email" className="icon" />
                  <a
                    className="contact-label"
                    rel="noreferrer"
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=5541997584227&text=Ol%C3%A1,%20meu%20nome%20%C3%A9%20"
                  >
                    +55 41 99758-4227
                  </a>
                </li>
              </ul>
            </div>
            <hr className="divider-col" />
            <h2>HISTÓRICO PROFISSIONAL</h2>
            <h3>Fuston Services - 02/2021 - Atual</h3>
            <h4>Desenvolvedor Back-end - NodeJS</h4>
            <div className="description">
              <ul>
                <li>
                  Atuei no desenvolvimento de microsserviços com mensageria,
                  utilizando NodeJS e Apache Kafka.
                </li>
                <li>
                  Efetuei integrações com API de terceiros para prover
                  funcionalidades voltadas ao chatbot de atendimento ao cliente.
                </li>
                <li>
                  Atuei em automações de fluxos, integrando o sistema de suporte
                  Zendesk com aplicações internas.
                </li>
                <li>
                  Apoiei o processo de evolução para Event-driven architecture.
                </li>
                <li>
                  Atuei na configuração do pipeline de CD/CI e deploy
                  automatizado, utilizando GitLab e Kubernetes, especificamente
                  EKS.
                </li>
                <li>
                  Apoiei na manutenção da infraestrutura como código (IaC),
                  utilizando Terraform, com provedor AWS.
                </li>
                <li>Bancos de dados relacionais: PostgreSQL.</li>
                <li>Bancos de dados não relacionais: Redis</li>
                <li>Message Brokers: Apache Kafka</li>
                <li>IaC: Terraform</li>
              </ul>
            </div>
            <h3>Tração Live Marketing - 03/2021 - 02/2022</h3>
            <h4>Desenvolvedor Full Stack</h4>
            <div className="description">
              <ul>
                <li>
                  Efetuei o levantamento de requisitos com o cliente e elaborei
                  a documentação do projeto e modelagem dos processos utilizando
                  a notação BPMN.
                </li>
                <li>
                  Desenvolvi a aplicação front-end, voltada para o gerenciamento
                  dos processos dos setores financeiro, atendimento, recursos
                  humanos e gerencial, utilizando React.
                </li>
                <li>
                  Desenvolvi a API back-end que provê as funcionalidades dos
                  processos dos setores citados acima, para servir o frontend,
                  utilizando NodeJS, com o framework NestJS.
                </li>
                <li>Bancos de dados relacionais: PostgreSQL.</li>
                <li>Bancos de dados não relacionais: Redis</li>
              </ul>
            </div>
            <h3>Fox Beauty 01/2018 - 03/2021</h3>
            <h4>Desenvolvedor Full Stack</h4>
            <div className="description">
              <ul>
                <li>
                  Atuei na manutenção da aplicação principal em .NET, corrigindo
                  bugs e implementando novas funcionalidades, voltadas ao
                  gerenciamento de salões de beleza e clínicas de estética.
                </li>
                <li>
                  Desenvolvi o aplicativo mobile de clientes, para agendamentos
                  de serviços online, utilizando React Native, integrado com a
                  aplicação principal.
                </li>
                <li>
                  Desenvolvi a API back-end que provê as funcionalidades para
                  servir o app mobile de clientes, utilizando NodeJS, com o
                  modelo de arquitetura REST, GraphQL e comunicação WebSocket
                  com Socket.IO.
                </li>
                <li>
                  Atuei na manutenção da API em ASP.NET, corrigindo bugs e
                  implementando novas funcionalidades, utilizando o modelo de
                  arquitetura REST e comunicação WebSocket com SignalR, que
                  provia informações analíticas e possibilitava sincronia de
                  informações comuns entre unidades de uma franquia.
                </li>
                <li>
                  Bancos de dados relacionais: SQL Server, PostgreSQL, MySQL.
                </li>
                <li>Bancos de dados não relacionais: MongoDB.</li>
              </ul>
            </div>
            <h3>Ultra Coders 01/2017 - 01/2018</h3>
            <h4>Desenvolvedor Web</h4>
            <div className="description">
              <ul>
                <li>
                  Atuei no desenvolvimento de websites institucionais,
                  utilizando HTML, CSS e JavaScript.
                </li>
                <li>Bancos de dados relacionais: MySQL.</li>
              </ul>
            </div>
            <hr className="divider-col" />
            <h2>FORMAÇÃO ACADÊMICA</h2>
            <h3> 06/2016</h3>
            <span>
              <strong>Centro Universitário Internacional Uninter</strong> |
              Curitiba
              <p> Tecnólogo em análise e desenvolvimento de sistemas</p>
            </span>
            <hr className="divider-col" />
            <h2>IDIOMAS</h2>

            <div className="list-langs">
              <ul>
                <li>
                  <strong>Português: </strong>
                  Língua nativa
                </li>
                <li>
                  <strong>Inglês: </strong>
                  Básico
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
