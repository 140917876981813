import "./App.less";

import Curriculo from "./pages/Curriculo";
import React from "react";

function App() {
  return <Curriculo />;
}

export default App;
