import { Action, Fab } from "react-tiny-fab";

import React from "react";
import { ShareAltOutlined } from "@ant-design/icons";

export default function FabButtons({ actions }: any) {
  return (
    <Fab
      icon={<ShareAltOutlined />}
      alwaysShowTitle={true}
      mainButtonStyles={{ background: "#002e5b" }}
    >
      {actions?.map((a: any) => (
        <Action
          key={a.text}
          text={a?.text}
          onClick={a?.handler}
          style={{ background: a?.color }}
        >
          <a
            href={a?.href}
            rel="noreferrer"
            target={a?.href && a?.href !== "#" ? "_blank" : "_self"}
            style={{ color: a?.linkColor }}
          >
            {a?.icon}
          </a>
        </Action>
      ))}
    </Fab>
  );
}
