import "../assets/fonts/Ubuntu/Ubuntu-Regular-normal";

import { Grid } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export async function gerarImagem() {
  var node = document.body.getElementsByClassName("container")[0] as any;

  const canvas = await html2canvas(node, { windowWidth: 1366, scale: 3 });

  const img = canvas.toDataURL("image/jpeg", 1.0);
  var imgWidth = (canvas.width * 60) / 240;
  var imgHeight = (canvas.height * 70) / 240;
  var pdf = new jsPDF({
    orientation: "p",
    unit: "px",
    format: [imgWidth, imgHeight],
  });
  pdf.addImage(img, "jpeg", 0, 0, imgWidth, imgHeight);
  pdf.save("curriculo");
}

export async function gerarPdf() {
  try {
    const node = document.getElementById("container");
    if (node) node.style.width = "1380px";

    const pdf = new jsPDF({
      unit: "px",
      format: [1280, 1280],
    });

    await pdf.html(node as any, {
      html2canvas: { windowWidth: 1280, scale: 0.93 },
    });
    pdf.deletePage(2);
    pdf.save("curriculo");
    if (node) node.style.width = "auto";
  } catch (error: Error | any) {
    throw error;
  }
}

export function UseBreakpointDemo() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screen[0]);
}
